























































import {Component, Prop, Vue} from "vue-property-decorator";
import PortalInput from "@/components/common/PortalInput.vue";
import IncomeTaxReturnRequest, {IncomeTaxReturnType} from "@/dto/payroll/IncomeTaxReturnRequest";
import FileUpload from "@/components/common/FileUpload.vue";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import Workspaces from "@/state/Workspaces";
import {namespace} from "vuex-class";
import PayrollService from "@/services/PayrollService";
import {ifValid} from "@/utils/ComponentUtils";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";

const AppModule = namespace("App");

@Component({
	components: {FileUpload, PortalInput}
})
export default class UploadIndividualIncomeTaxReturn extends Vue {

	private message = "";

	private successful = true;

	@AppModule.Action
	private startLoading!: () => void;

	@AppModule.Action
	private stopLoading!: () => void;

	@Prop()
	private category!: IncomeTaxReturnType;

	private file: FileMetaDTO | null = null;

	@Prop()
	private callback!: (year: string) => void

	private payload: IncomeTaxReturnRequest = new IncomeTaxReturnRequest()

	public created(): void {
		this.payload.category = this.category
		this.payload.personId = Workspaces.getCurrent.id;

	}

	private onFileLoaded(files: Array<FileMetaDTO>): void {
		this.file = files[0];
		this.payload.fileMetaId = this.file.id;
	}

	private remove(): void{
		this.file = null;
		this.payload.fileMetaId = null
	}

	private save(): void {
		if (!this.file) {
			this.successful = false;
			this.message = "File is mandatory field!"
		}
		ifValid(this, () => {
			this.startLoading();
			PayrollService.saveIncomeTaxList(this.payload).then(
				() => {
					this.successful = true;
					this.stopLoading();
					this.callback(this.payload.year!);
					this.$modal.hideAll()
				},
				err => {
					this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
					this.successful = false;
					this.stopLoading();
				}
			)
		})
	}
}

