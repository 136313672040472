import { render, staticRenderFns } from "./FederalIncomeTaxReturn.vue?vue&type=template&id=796cbc7e&scoped=true&"
import script from "./FederalIncomeTaxReturn.vue?vue&type=script&lang=ts&"
export * from "./FederalIncomeTaxReturn.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "796cbc7e",
  null
  
)

export default component.exports